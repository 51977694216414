

$.fn.isInViewport = function() {
	var elementTop = $(this).offset().top;
	var elementBottom = elementTop + $(this).outerHeight();
	var viewportTop = $(window).scrollTop();
	var viewportBottom = viewportTop + $(window).height();
return elementBottom > viewportTop && elementTop < viewportBottom;
};






"use strict";
var textAnimateTimer;
var wordAnimateTimer;
var sectionAnimationsSpeed = 500;
var teamSliderInterval;
var mobileSliderInterval;
var mobileSliderInterval2;
var fullpageSpeed=500;
var isMainPage=false;
var amountOfFullpageSlides=0;
var isFullpagePage=false;
var windowWidth=document.documentElement.clientWidth;
$(document).ready(function(){

	if($("body").hasClass("body--main")){
		isMainPage=true;
	}
	if($("body").hasClass("body--fullpage")){
		isFullpagePage=true;
	}

	initHomepageTeamSlider();
	initHomepageVisionSlider();
	initHomepageAboutUsSlider();
	initHomepageStepsSlider();
	amountOfFullpageSlides=$(".section").length;
    $('#fullpage').fullpage({
		licenseKey:"98765B2A-4DD5492F-B7AB50AA-58C8A77A",
		menu: '.nav__menu',
		css3:true,
		easingcss3:"cubic-bezier(0.39, 0.575, 0.565, 1)",
		scrollingSpeed: fullpageSpeed,
		keyboardScrolling: false,
		verticalCentered:true,
		scrollOverflow: true,
		onLeave:function(origin, destination, direction){
			$.fn.fullpage.setMouseWheelScrolling(false);
			$.fn.fullpage.setAllowScrolling(false);
			fadeOutContent();
			var index=destination.index;
			changeActiveMenuItem(index);
			changeMenuType(index);
			setTimeout(function(){
				checkScrollButtons(index)
				resetSlideInnerScroll(index);
				checkMobileSlider(index);
				checkFirstSlide(index);
				checkCanvas(index);
				checkAnimatedMedia(index);
				checkAnimatedText(index);
				checkAnimatedButtons(index);
				checkHomepageCards(index);
				checkHomepageSteps(index);
				checkTeamSlider(index);
				checkPhotography(index);
			},fullpageSpeed+sectionAnimationsSpeed);
			setTimeout(function(){
				$.fn.fullpage.setMouseWheelScrolling(true);
				$.fn.fullpage.setAllowScrolling(true);
			},fullpageSpeed+sectionAnimationsSpeed*4);
			
		}
	});
	let initialOffset = $('body').scrollTop();
	$('input, textarea, select').focus(function(e) {
	    let offset = $(e.target).offset();
	    initialOffset = $('body').scrollTop();
	    $('body').scrollTop(offset.top-100);
	});
	$('input, textarea, select').focusout(function(e) {
	    // Reset to the initial offset
	    $('body').scrollTop(initialOffset);
	});
	//$('input').focus(function() { $.fn.fullpage.rebuild(); })
	//$('input').blur(function() { $.fn.fullpage.rebuild(); })
	if(isFullpagePage){
		$.fn.fullpage.setAllowScrolling(false);
	}
	$(".section__controls__button--next").click(function(){
		$.fn.fullpage.moveSectionDown();
	})
	$(".section__controls__button--prev").click(function(){
		$.fn.fullpage.moveSectionUp();
	})
	$(".intro__description__arrow").click(function(){
		$.fn.fullpage.moveSectionDown();
	})
	
	
	$(".menu__burger").click(function(){
		$('.menu').toggleClass("menu--active");
	})

	$(".menu__list__unit a").hover(function(){
		var index=$(this).parents(".menu__list__unit").index();
		$(".menu__bg__unit").removeClass("menu__bg__unit--active");
		$(".menu__bg__unit").eq(index).addClass("menu__bg__unit--active");
	})

	$(".text-animated--eachWord").each(function(){
		var _this=$(this);
		var words=_this.html().split(" ");
		_this.html("");
		var wordsAmount=words.length;
		$.each(words,function(index,value){
			var word=`<span class="text-animated--eachWord__word"><span class="text-animated--eachWord__word__inner">${value} </span></span>`;
			if(index==wordsAmount-1){
				word=`<span class="text-animated--eachWord__word"><span class="text-animated--eachWord__word__inner">${value}</span></span>`;
			}
			_this.append(word);
		})
	})
	$(document).on("click",".mobile-slider .slick-next",function(){
		var slider=$(this).parents(".slick-slider:not(.homepage__section__steps):not(.homepage__team__wrap)");
		clearInterval(mobileSliderInterval);
		setMobileSliderInterval(slider);
	})
	
	$(document).on("click",function(e){
		var target=$(e.target);
		if(target.hasClass("nav")||target.parents().hasClass("nav")){
			if(target.hasClass("nav__menu__unit")||target.parents().hasClass("nav__menu__unit")){
				var index;
				if(target.hasClass("nav__menu__unit")){
					index=target.index();
				} else {
					index=target.parents(".nav__menu__unit").index();
				}
				if(isMainPage){
					if(index>4){
						$.fn.fullpage.moveTo(index, 0);
					} else{
						$.fn.fullpage.moveTo(index+1, 0);
					}	
				} else {
					$.fn.fullpage.moveTo(index+1, 0);
				}
				$(".nav").removeClass("nav--hover");
			} else{

				$(".nav").addClass("nav--hover");
			}
		} else {
			$(".nav").removeClass("nav--hover");
		}

	})
	
	$(".nav").on('mouseenter', function(){
     	$(this).addClass("nav--hover");
	});
	$(".nav").on('mouseleave', function(){
    	$(this).removeClass("nav--hover");
	});
	
	
	
	$(".team__slider").each(function(){
		var _this=$(this);
		var infoSlider=_this.find(".team__slider__info");
		var imagesSlider=_this.find(".team__slider__images");
		var prevArrow=_this.find(".team__slider__controls__unit--prev");
		var nextArrow=_this.find(".team__slider__controls__unit--next");
		imagesSlider.on('init', function(event, slick){
			var index=slick.currentSlide;
			var currentSlide=slick.$slides.eq(index);
			currentSlide.next(".slick-slide").addClass("team__slider__images__unit--highlighted");
			currentSlide.next(".slick-slide").nextAll(".team__slider__images__unit").addClass("team__slider__images__unit--moved");
			currentSlide.next(".slick-slide").nextAll(".team__slider__images__unit").eq(0).addClass("team__slider__images__unit--highlighted--next");
			currentSlide.next(".slick-slide").nextAll(".team__slider__images__unit").eq(1).addClass("team__slider__images__unit--highlighted--next--next");
		})
		imagesSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
			$(".team__slider__images__unit").removeClass("team__slider__images__unit--highlighted team__slider__images__unit--moved team__slider__images__unit--highlighted--next team__slider__images__unit--highlighted--next--next");
			var index=nextSlide;
			var currentSlide=slick.$slides.eq(index);
			currentSlide.next(".slick-slide").addClass("team__slider__images__unit--highlighted");
			currentSlide.next(".slick-slide").nextAll(".team__slider__images__unit").addClass("team__slider__images__unit--moved");
			currentSlide.next(".slick-slide").nextAll(".team__slider__images__unit").eq(0).addClass("team__slider__images__unit--highlighted--next");
			currentSlide.next(".slick-slide").nextAll(".team__slider__images__unit").eq(1).addClass("team__slider__images__unit--highlighted--next--next");
		})
		imagesSlider.slick({
			draggable:false,
			acessibility:false,
			swipe:false,
			asNavFor: infoSlider,
			arrows:false,
			speed:700,
			infinite:true,
			//variableWidth:true,
			slidesToShow:5,
			responsive:[
	  			{
	  				breakpoint:1024,
	  				settings:{
	  					slidesToShow:4,
	  				}
	  			},
	  			{
	  				breakpoint:761,
	  				settings:{
	  					swipe:true,
	  					variableWidth:true,
	  					//slidesToShow:1,
	  					centerMode:true,
	  				}
	  			},
  			]
		})
		infoSlider.slick({
			draggable:false,
			acessibility:false,
			swipe:false,
			slide:".team__slider__info__unit",
			infinite:true,
			speed:700,
			//fade:true,
			prevArrow:prevArrow,
			nextArrow:nextArrow,
			asNavFor: imagesSlider,
		})
		
	})
	$(".team__strengths__slider").on('beforeChange', function(event, slick, currentSlide, nextSlide){
		clearInterval(mobileSliderInterval);
		setMobileSliderInterval($(".team__strengths__slider"));
	})
	$(".team__strengths__slider").slick({
		prevArrow:"",
		mobileFirst: true,
		dots: true,
	})
	$(".team__diversity__text").on('beforeChange', function(event, slick, currentSlide, nextSlide){
		clearInterval(mobileSliderInterval2);
		setMobileSliderInterval($(".team__diversity__text"));
	})
	$(".team__diversity__text").slick({
		arrows:false,
		mobileFirst: true,
		dots: true,
		//fade:true,
        responsive: [
            {
                breakpoint: 760,
                settings: 'unslick'
            }
        ]
	})
	$('[data-fancybox]').fancybox({
	// Options will go here
	});
	$(".team__video__button").click(function(){
		var video=$(".team__video video");
		$('html, body').animate({
            scrollTop: $(".team__video").offset().top
        }, 500);
		$(".team__video").addClass("team__video--expanded");
		$(".team").addClass("team--video");
		$("body").removeClass("body--loaded");
		video.prop('muted', false);
		
	})
	$(".team__video__close").click(function(){
		var video=$(".team__video video");
		$(".team__video").removeClass("team__video--expanded");
		$(".team").removeClass("team--video");
		$("body").addClass("body--loaded");
		video.prop('muted', true);
	})
	$(".team__popup").click(function(){
		$(this).removeClass("team__popup--shown");
	})
	$(".team__popup video").click(function(e){
		e.stopPropagation();
	})
	$(".photography__hero__slider").each(function(){
		var slider=$(this);
		var hero=slider.parents(".photography__hero");
		var prevArrow=hero.find(".photography__hero__controls__unit--prev");
		var nextArrow=hero.find(".photography__hero__controls__unit--next");
		slider.slick({
			slide:".photography__hero__slider__unit",
			prevArrow:prevArrow,
			nextArrow:nextArrow,
			dots:true,
			fade:true,
			initialSlide:2,
			autoplay:true,
			autoplaySpeed:7000,
			appendDots:".photography__hero__slider__dots",
			customPaging : function(slider, i) {
	            var thumb = $(slider.$slides[i]).find(".photography__hero__slider__unit__image").css('background-image');
	            var item=$("<div class='photography__hero__slider__preview__unit' style='background-image:"+thumb+"'></div>");
	            return item;
        	},
		})
	})
	$(".photography__packages__unit__title").click(function(){
		var unit=$(this).parents(".photography__packages__unit");
		var siblings=unit.siblings(".photography__packages__unit")
		siblings.removeClass("photography__packages__unit--expanded");
		siblings.find(".photography__packages__unit__body").css("max-height",0+"px");
		var innerBody=unit.find(".photography__packages__unit__body__inner");
		var body=unit.find(".photography__packages__unit__body");
		var bodyHeight=innerBody.outerHeight(true);
		if(unit.hasClass("photography__packages__unit--expanded")){
			unit.removeClass("photography__packages__unit--expanded");
			body.css("max-height",0+"px");
		} else {
			unit.addClass("photography__packages__unit--expanded");
			body.css("max-height",bodyHeight+"px");
		}
	})
	$(".photography__gallery").slick({
		arrows:false,
		mobileFirst: true,
		dots: false,
		//fade:true,
		centerMode:true,
		variableWidth:true,
        responsive: [
            {
                breakpoint: 760,
                settings: 'unslick'
            }
        ]
	})
	$(".contacts__form__close").click(function(){
		$('.contacts__form').removeClass("contacts__form--shown");
		clearContactsForm();
	})
	$(".contacts__form__footer__back").click(function(){
		$('.contacts__form').removeClass("contacts__form--shown");
		clearContactsForm();
	})
	$("#open-form").click(function(){
		$('.contacts__form').addClass("contacts__form--shown");
		showFormSegment(0);
	})
	$('.contacts__form input ').on("keyup",function(event){
	    var keycode = (event.keyCode ? event.keyCode : event.which);
	    var segment=$(this).parents(".contacts__form__segment")
	    var index=segment.index();
	   	var segmentIsFilled=checkFormSegmentSiblings(segment);
	   	if(keycode == '13'){
		   	if(!($(this).is("#start-date")||$(this).is("#email"))){
			   	if(segmentIsFilled){
			        showFormSegment(index+1);
			    }
			} else{
				console.log(1);
				console.log($(this))
				if($(this).val()!=""&&$(this).is("#start-date")){
					$("#finish-date").focus();
				} else if($(this).val()!=""&&$(this).is("#email")){
					$("#phone").focus();
					console.log(2);
				}
			}
		}
	});
	$('.contacts__form textarea').on("keypress",function(event){
	    var keycode = (event.keyCode ? event.keyCode : event.which);
	    var segment=$(this).parents(".contacts__form__segment")
	    var index=segment.index();
	   	var segmentIsFilled=checkFormSegmentSiblings(segment);
	   	if(segmentIsFilled&&keycode == '13'){
	        showFormSegment(index+1);
	    }
	});
	$(".contacts__form__footer__next").click(function(){
		var segment=$(".contacts__form__segment--shown").last();
		var index=segment.index();
		var segmentIsFilled=checkFormSegmentSiblings(segment);
		if(segmentIsFilled){
			showFormSegment(index+1);
		}
	})
	$(".contacts__form__unit__body--close").click(function(){
		$('.contacts__form').removeClass("contacts__form--shown");
		clearContactsForm();
	})
	$(".intro__scroll").click(function(){
		if($(this).hasClass("intro__scroll--work")){
			$.fn.fullpage.moveSectionDown();
		} else{
			animateIntro();
		}
	})

});
function animateFormProgressBar(index){
	var allUnits=$(".contacts__form__segment").length;
	var progressWidth=(index+1)/allUnits;
	$(".contacts__form__progressbar__inner").css("transform",'scaleX('+progressWidth+")");
}
function isEmail(email) {
  var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
}
function checkFormSegmentSiblings(segment){
	var segmentIsFilled=true;
	segment.find("textarea,input").each(function(){
		if(!$(this).attr("type")=="phone"){
			if($(this).val()==""){
				segmentIsFilled=false;
			} 
			if($(this).attr("type")=="email"){
				if(!isEmail($(this).val())){
					segmentIsFilled=false;
				}
			}
		}
	})
	return segmentIsFilled
}
function checkIfMainPage(){
	if($("body").hasClass("body--main")){
		isMainPage=true;
	}
}
function clearContactsForm(){
	setTimeout(function(){
		$(".contacts__form__progressbar__inner").css("transform",'scaleX('+0+")");
		$(".contacts__form__segment").removeClass("contacts__form__segment--shown");
		$(".contacts__form").find("input:not([type='radio'])").val("");
		$(".contacts__form").find("textarea").val("");
	},800)
	
}
function showFormSegment(index){

	var form=$(".contacts__form");
	var segment=form.find(".contacts__form__segment");
	var currentSegment=segment.eq(index);
	var windowHeight=window.innerHeight;
	currentSegment.addClass("contacts__form__segment--shown");
	
	setTimeout(function(){
		$('.contacts__form__wrap').animate({
       		scrollTop: currentSegment.position().top - windowHeight/5
    	}, 500);
    	animateFormProgressBar(index);
    	if(currentSegment.find("input,textarea").length>0){
    		currentSegment.find("input,textarea")[0].focus();
    	}
	},500)
    if(index==1){
    	var name=$("#name").val();
    	$("#person-name").html(name);
    }
}
function n() {
    var n = document.getElementById("result__bg")
      , t = document.createElement("div");
    t.className = "result__bg__threejs",
    n.appendChild(t),
    r = new THREE.PerspectiveCamera(75,window.innerWidth / window.innerHeight,1,1e4),
    r.position.z = 400;
    a = new THREE.Scene,
    w = new Array;
    for (var o = Math.PI, p = new THREE.SpriteCanvasMaterial({
        color: 16777215,
        program: function(n) {
            n.beginPath(),
            n.arc(0, 0, .25, 0, o, !0),
            n.fill()
        }
    }), E = 0, m = 0; m < h; m++)
        for (var u = 0; u < l; u++)
            s = w[E++] = new THREE.Sprite(p),
            s.position.x = m * c - h * c / 2,
            s.position.z = u * c - l * c / 2,
            a.add(s);
    d = new THREE.CanvasRenderer({
        alpha: !0
    }),
    d.setPixelRatio(window.devicePixelRatio),
    d.setSize(window.innerWidth, window.innerHeight),
    t.appendChild(d.domElement),
    document.addEventListener("mousemove", e, !1),
    window.addEventListener("resize", i, !1)
}
function i() {
    u = window.innerWidth / 2,
    v = window.innerHeight / 2,
    r.aspect = window.innerWidth / window.innerHeight,
    r.updateProjectionMatrix(),
    d.setSize(window.innerWidth, window.innerHeight)
}
function e(n) {
    E = n.clientX - u,
    m = n.clientY - v
}
function t() {
    requestAnimationFrame(t),
    o()
}
function o() {
    r.position.x += .01 * (E - r.position.x),
    r.position.y += .05 * (-m - r.position.y + 1500),
    r.lookAt(a.position);
    for (var n = 0, i = 0; i < h; i++)
        for (var e = 0; e < l; e++)
            s = w[n++],
            s.position.y = 50 * Math.sin(.2 * (i + p)) + 50 * Math.sin(.5 * (e + p)),
            s.scale.x = s.scale.y = 4 * (Math.sin(.3 * (i + p)) + 1) + 4 * (Math.sin(.5 * (e + p)) + 1);
    d.setClearColor(0, 0),
    d.render(a, r),
    p += .1
}
var r, a, d, w, s, c = 100, h = 120, l = 120, p = 0, E = 0, m = 0, u = window.innerWidth / 2, v = window.innerHeight / 2;

var canvasRendered=false; 

function initHomepageTeamSlider(){
	$(".homepage__team").each(function(){
		var team=$(this);
		var slider=team.find(".homepage__team__wrap");
		var prev=team.find(".homepage__team__controls__unit--prev");
		var next=team.find(".homepage__team__controls__unit--next");
		
		slider.on('init', function(event, slick){
			var index=slick.currentSlide;
			var currentSlide=slick.$slides.eq(index);
			var sideLeftSlide=currentSlide;
			var sideRightSlide=currentSlide.nextAll().eq(3);
			sideLeftSlide.addClass("homepage__team__unit--side homepage__team__unit--side--left");
			sideRightSlide.addClass("homepage__team__unit--side homepage__team__unit--side--right");
        })
        slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){

			$(".homepage__team__unit").removeClass("homepage__team__unit--timer homepage__team__unit--side homepage__team__unit--side--left homepage__team__unit--side--right");
			var index=nextSlide;
			var currentSlide=slick.$slides.eq(index);
			var sideLeftSlide=currentSlide;
			var sideRightSlide=currentSlide.nextAll().eq(3);
			sideLeftSlide.addClass("homepage__team__unit--side homepage__team__unit--side--left");
			sideRightSlide.addClass("homepage__team__unit--side homepage__team__unit--side--right");
        })
        slider.on('afterChange', function(event, slick, currentSlide){
        	var index=currentSlide;
        	var activeSlide=slick.$slides.eq(index);
        	findCenterTeamSlide(activeSlide);
        	setTeamSliderInterval();
        })
        
		slider.slick({
			slide:".homepage__team__unit",
			speed:700,
			slidesToShow:5,
			//swipe:false,
			//swipeToSlide:false,
  			prevArrow:prev,
  			nextArrow:next,
  			responsive:[
	  			{
	  				breakpoint:1024,
	  				settings:{
	  					slidesToShow:3,
	  				}
	  			},
	  			{
	  				breakpoint:760,
	  				settings:{
	  					centerMode:true,
	  					variableWidth:true,
	  				
	  				}
	  			},
  			]
		})
	})
}
function resetSlideInnerScroll(index){
	var section=$(".section").eq(index);

	var scroller=section.find(".fp-scroller");
	if(scroller.length>0){
		var scrollBar=section.find(".iScrollIndicator");
		var scrollerStyles=scroller.attr("style");
		var scrollBarStyles=scrollBar.attr("style");
		scrollerStyles=scrollerStyles.replace(/transform:\s*(.*?)\s*;/g,"transform:translate(0px,0px) translateZ(0px)");
		scrollBarStyles=scrollBarStyles.replace(/transform:\s*(.*?)\s*;/g,"transform:translate(0px,0px) translateZ(0px)");
		scrollBar.attr("style",scrollBarStyles);
		scroller.attr("style",scrollerStyles);

		$.each(window.fp_scrolloverflow.iscrollHandler.iScrollInstances,function(index,value){
			if(scroller[0]==value.scroller){
				value.y=0;
			}
		});
	}
	
}
function checkScrollButtons(index){
	if(index==0){
		$(".section__controls").addClass("section__controls--first");
	} else if(index==amountOfFullpageSlides-1){
		$(".section__controls").addClass("section__controls--last");
	}
	$(".section__controls").addClass("section__controls--shown");
}
function findCenterTeamSlide(activeSlide){
	var windowWidth=document.documentElement.clientWidth;
	var slideToAimNumber;
	var timerSlide;
	if(windowWidth<760){
		slideToAimNumber=-1;
	} else if(windowWidth<1024){
		slideToAimNumber=0;
	} else {
		slideToAimNumber=1;
	}
	if(slideToAimNumber>=0){
		timerSlide=activeSlide.nextAll().eq(slideToAimNumber);
	} else {
		timerSlide=activeSlide;
	}
	timerSlide.addClass("homepage__team__unit--timer");
}

function initHomepageVisionSlider(){
	$(".homepage__section__cards").slick({
		prevArrow:"",
		mobileFirst: true,
		dots: true,
		//fade:true,
        responsive: [
            {
                breakpoint: 1023,
                settings: 'unslick'
            }
        ]
	})
}

function initHomepageAboutUsSlider(){
	$(".homepage__section__text--aboutus").on('beforeChange', function(event, slick, currentSlide, nextSlide){
		if(nextSlide==2){
			$(this).prev(".homepage__section__title").addClass("homepage__section__title--alt");
		} else{
			$(this).prev(".homepage__section__title").removeClass("homepage__section__title--alt");
		}
	})
	$(".homepage__section__text--aboutus").slick({
		arrows:false,
		mobileFirst: true,
		dots: true,
		//fade:true,
        responsive: [
            {
                breakpoint: 760,
                settings: 'unslick'
            }
        ]
	})
}

function initHomepageStepsSlider(){
	$(".homepage__section__steps").slick({
		mobileFirst: true,
		dots: false,
		//fade:true,
		infinite:false,
        responsive: [
            {
                breakpoint: 760,
                settings: 'unslick'
            }
        ]
	})
}

function checkCanvas(index){
	if($(".section").eq(index).hasClass("homepage__section--result")){
		if(canvasRendered==false){
			canvasRendered=true;
			n();
			t();
		}
	}
}

function changeMenuType(index){
	if(index==0||index==amountOfFullpageSlides-1){
		$(".header").removeClass("header--translated");
		$('.menu__burger').removeClass("menu__burger--shown");
	} else {
		$(".header").addClass("header--translated");
		$('.menu__burger').addClass("menu__burger--shown");
	}
}

function changeActiveMenuItem(index){
	var menuUnit=$(".nav__menu__unit");
	var activeClass="nav__menu__unit--active nav__menu__unit--prev";
	menuUnit.removeClass(activeClass);
	var menuUnitActive;
	if(isMainPage){
		if(index<8&&index>=4){
			menuUnit.eq(4).addClass(activeClass);
			menuUnitActive=menuUnit.eq(index+1);
		} else if(index>7)  {
			menuUnitActive=menuUnit.eq(index+1);
		} else{
			menuUnitActive=menuUnit.eq(index);
		}
	} else{
		menuUnitActive=menuUnit.eq(index);
	}
	menuUnitActive.addClass(activeClass);
	menuUnitActive.prevAll(".nav__menu__unit").addClass("nav__menu__unit--prev");
}

function checkFirstSlide(index){
	if(index!=0){
		$(".nav").addClass("nav--filled");
	} else {
		$(".nav--filled").removeClass("nav--filled")
	}
}

function fadeOutContent(){
	clearTimeout(textAnimateTimer);
	clearTimeout(wordAnimateTimer);
	clearInterval(teamSliderInterval);
	clearInterval(mobileSliderInterval);
	$(".text-animated--eachWord__word__inner").removeClass("text-animated--eachWord__word__inner--visible");
	$(".text-animated--eachWord--visible").removeClass("text-animated--eachWord--visible");
	$(".text-animated--shifted--visible").removeClass('text-animated--shifted--visible');
	$(".button--animated--visible").removeClass("button--animated--visible");
	$(".homepage__section__media--shown").removeClass("homepage__section__media--shown");
	$(".homepage__section__cards__unit--shown").removeClass("homepage__section__cards__unit--shown");
	$(".homepage__section__steps__unit--shown").removeClass("homepage__section__steps__unit--shown");
	$(".homepage__team__unit--shown").removeClass("homepage__team__unit--shown");
	$(".homepage__team__unit--timer").removeClass("homepage__team__unit--timer");
	$(".photo__grid__column__unit--shown").removeClass("photo__grid__column__unit--shown");
	$(".mobile-slider--shown").removeClass("mobile-slider--shown");
	$(".intro__scroll--work").removeClass("intro__scroll--shown");
	$(".section__controls").removeClass("section__controls--first section__controls--last");
}

function checkAnimatedMedia(index){
	var section=$(".section").eq(index);
	section.find(".homepage__section__media").each(function(index,value){
		var mediaIndex=index;
		var mediaUnit=$(this);
		setTimeout(function(){
			mediaUnit.addClass("homepage__section__media--shown");
		},100*mediaIndex)
	})
}
function checkMobileSlider(index){
	var section=$(".section").eq(index);
	section.find(".mobile-slider").addClass("mobile-slider--shown");
	var slider=section.find(".slick-slider:not(.homepage__section__steps):not(.homepage__team__wrap)");
	var currentSlide=slider.slick('slickCurrentSlide');
	if(slider.length>0){
		setMobileSliderInterval(slider);
		/* resetting timer on slick dots */
		slider.slick('slickGoTo', currentSlide+1, true);
		setTimeout(function(){
			slider.slick('slickGoTo', currentSlide, true);
		},110)
	}
}

function checkHomepageCards(index){
	var section=$(".section").eq(index);
	section.find(".homepage__section__cards__unit").addClass("homepage__section__cards__unit--shown");
}

function checkPhotography(index){
	var section=$(".section").eq(index);
	section.find(".photo__grid__column__unit").addClass("photo__grid__column__unit--shown");
}

function checkHomepageSteps(index){
	var section=$(".section").eq(index);
	section.find(".homepage__section__steps__unit").addClass("homepage__section__steps__unit--shown");
}

function checkAnimatedText(index){
	var section=$(".section").eq(index);
	section.find(".intro__scroll--work").addClass("intro__scroll--shown");
	section.find(".text").each(function(index,value){
		var textIndex=index;
		var textUnit=$(this);
		textAnimateTimer=setTimeout(function(){
			if(textUnit.hasClass("text-animated--eachWord")){
				textUnit.addClass("text-animated--eachWord--visible");
				textUnit.find(".text-animated--eachWord__word__inner").each(function(index,value){
	    			var wordIndex=index;
	    			var innerWord=$(this);
	    			wordAnimateTimer=setTimeout(function(){
	    				innerWord.addClass("text-animated--eachWord__word__inner--visible");
	    			},wordIndex*100)
	    		})
			} else if(textUnit.hasClass("text-animated--shifted")){
				textUnit.addClass("text-animated--shifted--visible");
			}
		},100*textIndex)
		
	})
}

function setTeamSliderInterval(){
	clearInterval(teamSliderInterval);
	teamSliderInterval = setInterval(function(){
		$(".homepage__team__wrap").slick("slickNext");
	},4000)
}

function setMobileSliderInterval(slider){
	if(!slider.hasClass("team__diversity__text")){
		clearInterval(mobileSliderInterval);
		mobileSliderInterval = setInterval(function(){
			slider.slick("slickNext");
		},8000)
	} else {
		clearInterval(mobileSliderInterval2);
		mobileSliderInterval2 = setInterval(function(){
			slider.slick("slickNext");
		},8000)
	}
}

function checkTeamSlider(index){
	
	var section=$(".section").eq(index);
	var teamSlider=section.find(".homepage__team__wrap");
	var teamSlides=section.find(".homepage__team__unit--side--left").nextAll(".homepage__team__unit");
	var teamArray=[section.find(".homepage__team__unit--side--left")];
	section.find(".homepage__team__unit--side--left").prevAll(".homepage__team__unit").addClass("homepage__team__unit--shown");
	teamSlides.each(function(index,value){
		teamArray.push($(this));
	})
	$.each(teamArray,function(index,value){
		var cardIndex=index;
		var cardUnit=value;
		if(index<5){
			setTimeout(function(){
				cardUnit.addClass("homepage__team__unit--shown");
			},100*cardIndex)
		} else {
			cardUnit.addClass("homepage__team__unit--shown");
		}
	})
	var slickCurrent=teamSlider.find(".slick-current");
	findCenterTeamSlide(slickCurrent);
	if(teamSlider.length>0){
		setTeamSliderInterval();
	}
	
}

function checkAnimatedButtons(index){
	var section=$(".section").eq(index);
	section.find(".button--animated").each(function(index,value){
		var buttonIndex=index;
		var buttonUnit=$(this);
		setTimeout(function(){
			buttonUnit.addClass("button--animated--visible");
		},100*buttonIndex)
	})
}

function animateIntro(){
	$(".header").addClass("header--shown");
	if(isMainPage){
		$(".intro").addClass("intro--animated");
		$('.nav').addClass("nav--shown");
		setTimeout(function(){
			checkScrollButtons(0);
			checkAnimatedText(0);
			setTimeout(function(){
				$.fn.fullpage.setAllowScrolling(true);
			},1000)
		},3200)
	}
}

function detectSwipe(){

	var swipe = document.getElementById('intro');
	var touchstartY = 0;
	var touchendY = 0;

	swipe.addEventListener('touchstart', function(event) {
		touchstartY = event.changedTouches[0].screenY;
	}, false);

	swipe.addEventListener('touchend', function(event) {
		touchendY = event.changedTouches[0].screenY;
  		handleSwipe();
	}, false); 

	function handleSwipe() {
		var swiped = 'swiped: ';
		if (touchendY < touchstartY) {

		    animateIntro();
		}
	}
}

function detectWheel(){
	$(".intro").on("wheel",function(event){
		const delta = Math.sign(event.originalEvent.deltaY);
		if(delta==1){
			animateIntro();
		}
	})
}
function addHeaderListener(){
	$(window).on("load scroll",function(){
		var scrollTop=$(window).scrollTop();
		if(scrollTop>100){
			$(".header").addClass("header--translated");
			$(".menu__burger").addClass("menu__burger--shown");
		} else {
			$(".header").removeClass("header--translated");
			$(".menu__burger").removeClass("menu__burger--shown");
		}
	})
}

function checkFadeElementsOnRegularPage(){

	$(".text").each(function(index,value){
		if($(this).isInViewport()){
			var textIndex=index;
			var textUnit=$(this);
			textAnimateTimer=setTimeout(function(){
				if(textUnit.hasClass("text-animated--eachWord")){
					textUnit.addClass("text-animated--eachWord--visible");
					textUnit.find(".text-animated--eachWord__word__inner").each(function(index,value){
		    			var wordIndex=index;
		    			var innerWord=$(this);
		    			wordAnimateTimer=setTimeout(function(){
		    				innerWord.addClass("text-animated--eachWord__word__inner--visible");
		    			},wordIndex*100)
		    		})
				} else if(textUnit.hasClass("text-animated--shifted")){
					textUnit.addClass("text-animated--shifted--visible");
				}
			},100*textIndex)
		}
	})

	$(".team__video").each(function(){
		if($(this).isInViewport()){
			$(this).addClass("team__video--shown");
		}
	})
	
	$(".team__slider__images").each(function(){
		var slider=$(this).parents(".team__slider");
		if($(this).isInViewport()){
			var currentImage=slider.find(".team__slider__images__unit.slick-current");
			var slidesArray=[currentImage];
			var teamImageSlides=currentImage.nextAll(".team__slider__images__unit");
			currentImage.prevAll(".team__slider__images__unit").addClass("team__slider__images__unit--shown");
			teamImageSlides.each(function(index,value){
				slidesArray.push($(this));
			})
			$.each(slidesArray,function(index,value){
				var imageIndex=index;
				var imageUnit=value;

				if(index<5){
					setTimeout(function(){
						imageUnit.addClass("team__slider__images__unit--shown");
					},100*imageIndex)
				} else {
					imageUnit.addClass("team__slider__images__unit--shown");
				}
			})
			slider.find(".team__slider__info__unit").addClass("team__slider__info__unit--shown");
			slider.find(".team__slider__controls").addClass("team__slider__controls--shown");
		}

	})

	$(".team__strengths__slider").each(function(){
		var _this=$(this);
		if(_this.isInViewport()){
			_this.addClass("team__strengths__slider--shown");
		}
	})

	$(".page__media").each(function(){
		var _this=$(this);
		if(_this.isInViewport()){
			_this.addClass("page__media--shown");
		}
	})

	$(".button--animated").each(function(index,value){
		var buttonIndex=index;
		var buttonUnit=$(this);
		if(buttonUnit.isInViewport()){
			setTimeout(function(){
				buttonUnit.addClass("button--animated--visible");
			},100*buttonIndex)
		}
	})
	$(".mobile-slider:not(.mobile-slider--shown)").each(function(){
		var slider=$(this);
		if(slider.isInViewport()){
			slider.addClass("mobile-slider--shown");
			var currentSlide=slider.slick('slickCurrentSlide');
			if(slider.length>0){
				setMobileSliderInterval(slider);
				slider.slick('slickGoTo', currentSlide+1, true);
				setTimeout(function(){
					slider.slick('slickGoTo', currentSlide, true);
				},110)
			}
		}
	})
	$(".photography__gallery__unit").each(function(){
		var unit=$(this);
		if(unit.isInViewport()){
			unit.addClass("photography__gallery__unit--shown");
		}
	})
	$(".photography__person__image").each(function(){
		var unit=$(this);
		if(unit.isInViewport()){
			unit.addClass("photography__person__image--shown");
		}
	})
	$(".photography__packages__unit").each(function(){
		var unit=$(this);
		if(unit.isInViewport()){
			unit.addClass("photography__packages__unit--shown");
		}
	})
	$(".form__unit").each(function(){
		var unit=$(this);
		if(unit.isInViewport()){
			unit.addClass("form__unit--shown");
		}
	})
}

$(window).on('resize', function() {
	$(".slick-slider").each(function(){
		$(this).slick('resize');
	})
    
})

$(window).on("load",function(){
	checkIfMainPage();
	$(".preloader").addClass("preloader--hidden");
	$("body").addClass("body--loaded");
	addHeaderListener();
	setTimeout(function(){
		$(".intro").addClass("intro--loaded");
		if(!isMainPage&&!isFullpagePage){
			$(".header").addClass("header--shown");
			checkFadeElementsOnRegularPage();
		}
		if(isFullpagePage&&!isMainPage){
			$(".header").addClass("header--shown");
			$('.nav').addClass("nav--shown");
			
			setTimeout(function(){
				checkAnimatedText(0);
				checkAnimatedMedia(0);
				checkScrollButtons(0);
				$.fn.fullpage.setAllowScrolling(true);
			},1000)
			

		}
		
	},1000)
	if(isMainPage){
		setTimeout(function(){
			detectWheel();
			detectSwipe();
		},4000)	
	}

})

$(window).on("scroll",function(){
	checkFadeElementsOnRegularPage();
})

